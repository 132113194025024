import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  dsotoken: "",
  userName: "",
  bbrCode: "",
  bank: "",
  isLoggedIn: false,
  isDSOLoggedIn: false,
  login: (token) => {},
  dsoLogin: (token) => {},
  logout: () => {},
  dsoLogout: () => {},
  agnCode: "",
});

const calculateRemainingTIme = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();
  const remainingTime = adjExpirationTime - currentTime;
  return remainingTime;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationTime = localStorage.getItem("expirationTime");
  const remainingTime = calculateRemainingTIme(storedExpirationTime);

  if (remainingTime <= 60000) {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("userName");
    localStorage.removeItem("bbrCode");
    localStorage.removeItem("bank");
    return null;
  }

  return { token: storedToken, duration: remainingTime };
};

const retrieveDSOToken = () => {
  const dsoToken = localStorage.getItem("dsotoken");
  const storedExpirationTime = localStorage.getItem("exp");

  if (storedExpirationTime) {
    const bytes = CryptoJS.AES.decrypt(
      storedExpirationTime,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const decryptedExpirationTime = JSON.parse(
      bytes.toString(CryptoJS.enc.Utf8)
    );
    const remainingTime = calculateRemainingTIme(decryptedExpirationTime);

    if (remainingTime <= 60000) {
      localStorage.removeItem("dsotoken");
      localStorage.removeItem("dso");
      localStorage.removeItem("aCode");
      localStorage.removeItem("exp");

      return null;
    }
  } else if (storedExpirationTime == null) {
    localStorage.removeItem("dsotoken");
    localStorage.removeItem("dso");
    localStorage.removeItem("aCode");
    localStorage.removeItem("exp");

    return null;
  }

  return { dsotoken: dsoToken };
};

const retrieveAgnCode = () => {
  if ("aCode" in localStorage) {
    const agnCode = localStorage.getItem("aCode");
    const bytes = CryptoJS.AES.decrypt(
      agnCode,
      process.env.REACT_APP_ENCRYPTION_KEY
    );

    const decryptedAgnCode = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return { aCode: decryptedAgnCode };
  }
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  const dsoTokenData = retrieveDSOToken();
  const agnData = retrieveAgnCode();

  let initialToken;
  let initialDsoToken;
  let initialAgnCode;

  if (tokenData) {
    initialToken = tokenData.token;
  }

  if (dsoTokenData) {
    initialDsoToken = dsoTokenData.dsotoken;
  }

  if (agnData) {
    initialAgnCode = agnData.aCode;
  }

  const [token, setToken] = useState(initialToken);
  const [userName, setUserName] = useState(localStorage.getItem("userName"));
  const [bbrCode, setBbrCode] = useState(localStorage.getItem("bbrCode"));
  const [bank, setBank] = useState(localStorage.getItem("bank"));

  const [dsotoken, setDsoToken] = useState(initialDsoToken);

  const [agnCode, setAgnCode] = useState(initialAgnCode);

  const userIsLoggedIn = !!token;

  const DsoIsLoggedIn = !!dsotoken;

  const logoutHandler = () => {
    setToken(null);
    setUserName(null);
    setBbrCode(null);
    setBank(null);
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("userName");
    localStorage.removeItem("bbrCode");
    localStorage.removeItem("bank");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  };

  const loginHandler = (token, userName, bbrCode, expirationTime, bank) => {
    setToken(token);
    setUserName(userName);
    setBbrCode(bbrCode);
    setBank(bank);
    localStorage.setItem("token", token);
    localStorage.setItem("userName", userName);
    localStorage.setItem("bbrCode", bbrCode);
    localStorage.setItem("expirationTime", expirationTime);
    localStorage.setItem("bank", bank);

    const remainingTime = calculateRemainingTIme(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const dsoLoginHandler = (token, agnCode, expirationTime) => {
    setDsoToken(token);
    setAgnCode(agnCode);
    localStorage.setItem("dsotoken", token);

    localStorage.setItem(
      "aCode",
      CryptoJS.AES.encrypt(
        JSON.stringify(agnCode),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
    );

    localStorage.setItem(
      "exp",
      CryptoJS.AES.encrypt(
        JSON.stringify(expirationTime),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
    );
  };

  const dsoLogoutHandler = () => {
    setDsoToken(null);
    localStorage.removeItem("dsotoken");
    localStorage.removeItem("dso");
    localStorage.removeItem("aCode");
    localStorage.removeItem("exp");
  };

  //retrieve all the chunk data stored in localStorage and decrypt it to get the original data
  const getDecryptedData = (key) => {
    const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
      storedKey.startsWith(`${key}_`)
    );

    if (chunkKeys.length === 0) {
      // No chunks found for the given key
      return null;
    }

    // Concatenate and decrypt all chunks to get the encrypted data
    let encryptedData = "";
    for (const chunkKey of chunkKeys) {
      encryptedData += localStorage.getItem(chunkKey);
    }

    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  //break the encrypted data into smaller chunks and store them in localStorage
  const saveEncryptedData = (key, data) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();

    const chunkSize = 1024 * 1024; // 1 MB chunk size (adjust as needed)
    const totalChunks = Math.ceil(encryptedData.length / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, encryptedData.length);
      const chunk = encryptedData.substring(start, end);

      // Store each chunk in localStorage with a unique key
      localStorage.setItem(`${key}_${i}`, chunk);
    }
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData]);

  const contextValue = {
    token: token,
    userName: userName,
    bbrCode: bbrCode,
    isLoggedIn: userIsLoggedIn,
    isDSOLoggedIn: DsoIsLoggedIn,
    bank: bank,
    login: loginHandler,
    dsoLogin: dsoLoginHandler,
    logout: logoutHandler,
    dsoLogout: dsoLogoutHandler,
    agnCode: agnCode,
  };
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
