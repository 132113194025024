import { useContext, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import UserForm from "./components/UserForm";
import UserFormGcb from "./components/gcb/UserForm";
import UserFormSpectra from "./components/spectra/UserForm";
import UserFormAccess from "./components/access/UserForm";
// import UserFormOmnibsic from "./components/omnibsic/UserForm";
import UserFormAmenfiman from "./components/amenfiman/UserForm";
import UserFormOdotobri from "./components/odotobri/UserForm";
import UserFormAtwima from "./components/atwimakwanwoma/UserForm";
import UserFormFiaseman from "./components/fiaseman/UserForm";
import UserFormAmansie from "./components/amansie/UserForm";
import UserFormAhantaman from "./components/ahantaman/UserForm";
import UserFormGarural from "./components/garural/UserForm";
import GaHome from "./components/garural/GaHome";
import GaEnquiry from "./components/garural/WelcomeEnquiry";
import GaPendingBusiness from "./components/garural/PendingBusiness";
import UserFormAbokobi from "./components/abokobi/UserForm";
import UserFormAtw from "./components/atwimamponua/UserForm";
import UserFormYaa from "./components/yaaasantewaa/UserForm";
import UserFormAmanano from "./components/amanano/UserForm";
import UserFormAwutu from "./components/awutu/UserForm";
import UserFormCapital from "./components/capital/UserForm";
import UserFormEnyan from "./components/enyan/UserForm";
import UserFormAgency from "./components/UserFormAgency";
import GCBHome from "./components/gcb/GCBHomeNew";
import WelcomeGcbEnquiry from "./components/gcb/WelcomeGcbEnquiry";
import GCBPendingBusiness from "./components/gcb/PendingBusiness";
import AuthContext from "./store/auth-context";
import LoginPortal from "./components/adminportal/login";
import AdminPortal from "./components/adminportal/adminportal";
import AccountVerification from "./components/accountverification/accountverification";
import AmenfimanHome from "./components/amenfiman/AmenfimanHomeNew";
import AmenfimanEnquiry from "./components/amenfiman/WelcomeEnquiry";
import AmenfimanPendingBusiness from "./components/amenfiman/PendingBusiness";
import PaymentApp from "./components/payment/payment";
import AhantamanHome from "./components/ahantaman/AhantamanHome";
import AhantamanEnquiry from "./components/ahantaman/WelcomeEnquiry";
import AhantamanPendingBusiness from "./components/ahantaman/PendingBusiness";
import AtwimanHome from "./components/atwimakwanwoma/AtwimanHomeNew";
import AtwimaEnquiry from "./components/atwimakwanwoma/WelcomeEnquiry";
import AtwimaPendingBusiness from "./components/atwimakwanwoma/PendingBusiness";
import RetailHome from "./components/retail/RetailHome";
import AccountVerificationRetail from "./components/retail/accountverification";
import AccessHome from "./components/access/AccessHome";
import AccessEnquiry from "./components/access/WelcomeEnquiry";
import AccessPendingBusiness from "./components/access/PendingBusiness";
import RetailOnboarding from "./components/retail/RetailOnboardingDashboard";
import GPCCUserForm from "./components/gpcc/UserForm";
import AdminReport from "./components/adminportal/adminreport";
import AdminReportSuccessful from "./components/adminportal/adminreportsucessful";
import AdminReportApproved from "./components/adminportal/adminreportapproved";
import AdminReportRejected from "./components/adminportal/adminreportrejected";
import AdminPasswordChange from "./components/adminportal/passwordchange";
import AdminPortalDashbaord from "./components/adminportal/adminportaldashboard";
import AdminReportSuccessfulReginal from "./components/adminportal/adminreportsucessfulregional";
import AdminReportSummaryReginal from "./components/adminportal/adminreportsummaryregional";
import AdminPortalCentral from "./components/adminportal/adminportalcentral";
import AdminReportSuccessfulCentral from "./components/adminportal/adminreportsucessfulcentral";
import ForgotPassword from "./components/adminportal/forgotpassword";
import ForgotPasswordChange from "./components/adminportal/forgotpasswordchange";
import YaaHome from "./components/yaaasantewaa/YaaHome";
import YaaEnquiry from "./components/yaaasantewaa/WelcomeEnquiry";
import YaaPendingBusiness from "./components/yaaasantewaa/PendingBusiness";
import AtwimanMponuaHome from "./components/atwimamponua/Home";
import AtwimaMponuaEnquiry from "./components/atwimamponua/WelcomeEnquiry";
import AtwimaMponuaPendingBusiness from "./components/atwimamponua/PendingBusiness";
import OdotobriHome from "./components/odotobri/Home";
import OdotobriEnquiry from "./components/odotobri/WelcomeEnquiry";
import OdotobriPendingBusiness from "./components/odotobri/PendingBusiness";
import SouthAkimHome from "./components/southakim/Home";
import SouthakimEnquiry from "./components/southakim/WelcomeEnquiry";
import SouthakimPendingBusiness from "./components/southakim/PendingBusiness";
import UserFormSouthakim from "./components/southakim/UserForm";
import UserFormAtwimamponua from "./components/atwimamponua/UserForm";
import FiasemanHome from "./components/fiaseman/Home";
import FiasemanEnquiry from "./components/fiaseman/WelcomeEnquiry";
import FiasemanPendingBusiness from "./components/fiaseman/PendingBusiness";

import CryptoJS from "crypto-js";
import AgentWelcomeLogin from "./components/AgentWelcomeLogin";
import UserFormAgencyRegister from "./components/UserFormAgencyRegister";
import AgentForgotPassword from "./components/AgentForgotPassword";

import TeleHome from "./components/telesales/GCBHomeNew";
import WelcomeTeleEnquiry from "./components/telesales/WelcomeGcbEnquiry";
import TelePendingBusiness from "./components/telesales/PendingBusiness";
import UserFormTele from "./components/telesales/UserForm";

import OmniHome from "./components/omnibsic/GCBHomeNew";
import WelcomeOmniEnquiry from "./components/omnibsic/WelcomeGcbEnquiry";
import OmniPendingBusiness from "./components/omnibsic/PendingBusiness";
import UserFormOmni from "./components/omnibsic/UserForm";

const NoMatchPage = () => {
  return (
    <div style={{ color: "#763984" }}>
      <br />
      <h3
        style={{
          background: "#fff",
          position: "relative",
          font: "900 30vmin Consolas",
          letterSpacing: "5vmin",
          textShadow:
            "2px -1px 0 #000, 4px -2px 0 #0a0a0a, 6px -3px 0 #0f0f0f, 8px -4px 0 #141414, 10px -5px 0 #1a1a1a, 12px -6px 0 #1f1f1f, 14px -7px 0 #242424, 16px -8px 0 #292929",
        }}
      >
        404
      </h3>
      <span
        style={{
          font: "400 5vmin Courgette",
        }}
      >
        Ooops...
        <br />
        page not found
      </span>
    </div>
  );
};

function App() {
  const authCtx = useContext(AuthContext);
  let navigate = useNavigate();

  //retrieve all the chunk data stored in localStorage and decrypt it to get the original data
  const getDecryptedData = (key) => {
    const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
      storedKey.startsWith(`${key}_`)
    );

    if (chunkKeys.length === 0) {
      // No chunks found for the given key
      return null;
    }

    // Concatenate and decrypt all chunks to get the encrypted data
    let encryptedData = "";
    for (const chunkKey of chunkKeys) {
      encryptedData += localStorage.getItem(chunkKey);
    }

    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  useEffect(() => {
    const tabId = generateUniqueId();
    
    // Set the current tab's ID in localStorage
    localStorage.setItem('dibatevitca', tabId);

    // Function to check if this is the active tab
    const checkActiveTab = () => {
      const activeTabId = localStorage.getItem('dibatevitca');
      if (activeTabId !== tabId) {
        // This is not the active tab, so we should close it or redirect
        alert('You already have an open tab.');
        window.location.href = "about:blank";
      }
    };

    // Check immediately
    checkActiveTab();

    // Set up interval to check periodically
    const intervalId = setInterval(checkActiveTab, 1000);

    // Clean up on unmount
    return () => {
      clearInterval(intervalId);
      if (localStorage.getItem('dibatevitca') === tabId) {
        localStorage.removeItem('dibatevitca');
      }
    };
  }, []);

  // Helper function to generate a unique ID
  function generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "" ? (
              <RetailHome />
            ) : authCtx.isDSOLoggedIn &&
              getDecryptedData("currDSO")["partner"] ===
                "AMENFIMAN RURAL BANCASSURANCE" ? (
              <AmenfimanHome />
            ) : authCtx.isDSOLoggedIn &&
              getDecryptedData("currDSO")["partner"] === "GCB BANCASSURANCE" ? (
              <GCBHome />
            ) : (
              <AgentWelcomeLogin />
            )
          }
        />
        <Route path="/register" element={<UserFormAgencyRegister />} />
        <Route path="/forgot-password" element={<AgentForgotPassword />} />
        <Route
          path="/retail"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "" ? (
              <RetailHome />
            ) : (
              <AgentWelcomeLogin />
            )
          }
        />
        <Route
          path="/bancassurance"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "AMENFIMAN RURAL BANCASSURANCE" ? (
              <AmenfimanHome />
            ) : authCtx.isDSOLoggedIn &&
              getDecryptedData("currDSO")["partner"] === "GCB BANCASSURANCE" ? (
              <GCBHome />
            ) : (
              <AgentWelcomeLogin />
            )
          }
        />
        {/* <Route path="/dashboard" element={<UserForm />} /> */}
        {/* <Route
          path="/retail/accountverification"
          element={<AccountVerification />}
        /> */}
        <Route
          path="/retail/spectra/onboarding"
          element={<UserFormSpectra />}
        />
        <Route
          path="/bancassurance/portal/login"
          element={
            authCtx.isLoggedIn &&
            (authCtx.bbrCode === 2 || authCtx.bbrCode === "2") ? (
              <AdminPortalDashbaord />
            ) : authCtx.isLoggedIn &&
              (authCtx.bbrCode !== 2 || authCtx.bbrCode !== "2") ? (
              <AdminPortal />
            ) : (
              <LoginPortal />
            )
          }
        />
        <Route
          path="/bancassurance/portal/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          path="/bancassurance/portal/forgot-password/:token"
          element={<ForgotPasswordChange />}
        />
        <Route
          path="/bancassurance/portal/home"
          element={
            authCtx.isLoggedIn &&
            (authCtx.bbrCode === 2 || authCtx.bbrCode === "2") ? (
              <AdminPortalDashbaord />
            ) : authCtx.isLoggedIn &&
              (authCtx.bbrCode === 3 || authCtx.bbrCode === "3") ? (
              <AdminPortalCentral />
            ) : authCtx.isLoggedIn &&
              (authCtx.bbrCode !== 2 ||
                authCtx.bbrCode !== "2" ||
                authCtx.bbrCode !== 3 ||
                authCtx.bbrCode !== "3") ? (
              <AdminPortal />
            ) : (
              <Navigate replace to={"/bancassurance/portal/login"} />
            )
          }
        />
        <Route
          path="/bancassurance/portal/report"
          element={
            authCtx.isLoggedIn &&
            (authCtx.bbrCode === 2 || authCtx.bbrCode === "2") ? (
              <AdminReport />
            ) : authCtx.isLoggedIn &&
              (authCtx.bbrCode !== 2 || authCtx.bbrCode !== "2") ? (
              <AdminReportSuccessful />
            ) : (
              <Navigate replace to={"/bancassurance/portal/login"} />
            )
          }
        />
        <Route
          path="/bancassurance/portal/report/successful"
          element={
            authCtx.isLoggedIn &&
            (authCtx.bbrCode === 2 || authCtx.bbrCode === "2") ? (
              // <AdminReportSuccessfulReginal />
              <AdminReport />
            ) : authCtx.isLoggedIn &&
              (authCtx.bbrCode === 3 || authCtx.bbrCode === "3") ? (
              <AdminReportSuccessfulCentral />
            ) : authCtx.isLoggedIn &&
              (authCtx.bbrCode !== 2 ||
                authCtx.bbrCode !== "2" ||
                authCtx.bbrCode !== 3 ||
                authCtx.bbrCode !== "3") ? (
              <AdminReportSuccessful />
            ) : (
              <Navigate replace to={"/bancassurance/portal/login"} />
            )
          }
        />
        <Route
          path="/bancassurance/portal/report/summary"
          element={
            authCtx.isLoggedIn &&
            (authCtx.bbrCode === 2 || authCtx.bbrCode === "2") ? (
              <AdminReportSummaryReginal />
            ) : (
              <Navigate replace to={"/bancassurance/portal/login"} />
            )
          }
        />
        <Route
          path="/bancassurance/portal/report/detail"
          element={
            authCtx.isLoggedIn &&
            (authCtx.bbrCode === 2 || authCtx.bbrCode === "2") ? (
              <AdminReportSuccessfulReginal />
            ) : (
              <Navigate replace to={"/bancassurance/portal/login"} />
            )
          }
        />
        <Route
          path="/bancassurance/portal/report/approved"
          element={
            authCtx.isLoggedIn ? (
              <AdminReportApproved />
            ) : (
              <Navigate replace to={"/bancassurance/portal/login"} />
            )
          }
        />
        <Route
          path="/bancassurance/portal/report/rejected"
          element={
            authCtx.isLoggedIn ? (
              <AdminReportRejected />
            ) : (
              <Navigate replace to={"/bancassurance/portal/login"} />
            )
          }
        />
        <Route
          path="/bancassurance/portal/change-password"
          element={
            authCtx.isLoggedIn ? (
              <AdminPasswordChange />
            ) : (
              <Navigate replace to={"/bancassurance/portal/login"} />
            )
          }
        />
        <Route
          path="/retail/starlife"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "" ? (
              <RetailHome />
            ) : (
              <Navigate replace to={"/retail"} />
            )
          }
        />
        <Route
          path="/retail/starlife/accountverification"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "" ? (
              <AccountVerificationRetail />
            ) : (
              <Navigate replace to={"/retail"} />
            )
          }
        />
        {/* GCB Routes */}
        <Route
          path="/bancassurance/gcb"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "GCB BANCASSURANCE" ? (
              <GCBHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route path="/bancassurance/gcb/onboarding" element={<UserFormGcb />} />
        <Route
          path="/bancassurance/gcb/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "GCB BANCASSURANCE" ? (
              <WelcomeGcbEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/gcb/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "GCB BANCASSURANCE" ? (
              <GCBPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />

        {/* Telesales Routes */}
        {/* <Route
          path="/bancassurance/gcb/telesales"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "GCB BANCASSURANCE TELESALES" ? (
              <TeleHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/gcb/telesales/onboarding"
          element={<UserFormTele />}
        />
        <Route
          path="/bancassurance/gcb/telesales/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "GCB BANCASSURANCE TELESALES" ? (
              <WelcomeTeleEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
          // element={<WelcomeGcbEnquiry />}
        />
        <Route
          path="/bancassurance/gcb/telesales/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "GCB BANCASSURANCE TELESALES" ? (
              <TelePendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        /> */}
        {/* Access Routes */}
        <Route
          path="/bancassurance/access"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ACCESS BANCASSURANCE" ? (
              <AccessHome />
            ) : authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ACCESS BANCASSURANCE STAFF" ? (
              <AccessHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/access/onboarding"
          element={<UserFormAccess />}
        />
        <Route
          path="/bancassurance/access/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ACCESS BANCASSURANCE" ? (
              <AccessEnquiry agn_code={authCtx.agnCode} />
            ) : authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ACCESS BANCASSURANCE STAFF" ? (
              <AccessEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
          // element={<WelcomeGcbEnquiry />}
        />
        <Route
          path="/bancassurance/access/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ACCESS BANCASSURANCE" ? (
              <AccessPendingBusiness />
            ) : authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ACCESS BANCASSURANCE STAFF" ? (
              <AccessPendingBusiness />
            ): (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        {/* OMNI BSCI Routes */}
        {/* <Route path="/bancassurance/omnibsic" element={<UserFormOmnibsic />} /> */}
        <Route
          path="/bancassurance/omnibsic"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "BSIC BANCASSURANCE" ? (
              <OmniHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route path="/bancassurance/omnibsic/onboarding" element={<UserFormOmni />} />
        <Route
          path="/bancassurance/omnibsic/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "BSIC BANCASSURANCE" ? (
              <WelcomeOmniEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/omnibsic/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "BSIC BANCASSURANCE" ? (
              <OmniPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        {/* AMENFIMAN ROUTES */}
        <Route
          path="/bancassurance/amenfiman"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "AMENFIMAN RURAL BANCASSURANCE" ? (
              <AmenfimanHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/amenfiman/onboarding"
          element={<UserFormAmenfiman />}
        />
        <Route
          path="/bancassurance/amenfiman/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "AMENFIMAN RURAL BANCASSURANCE" ? (
              <AmenfimanEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/amenfiman/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "AMENFIMAN RURAL BANCASSURANCE" ? (
              <AmenfimanPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/atwimakwanwoma"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ATWIMA KWANWOMA RURAL BANCASSURANCE" ? (
              <AtwimanHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/atwimakwanwoma/onboarding"
          element={<UserFormAtwima />}
        />
        <Route
          path="/bancassurance/atwimakwanwoma/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ATWIMA KWANWOMA RURAL BANCASSURANCE" ? (
              <AtwimaEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
          // element={<WelcomeGcbEnquiry />}
        />
        <Route
          path="/bancassurance/atwimakwanwoma/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ATWIMA KWANWOMA RURAL BANCASSURANCE" ? (
              <AtwimaPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/odotobri"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ODOTOBRI RURAL BANCASSURANCE" ? (
              <OdotobriHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/odotobri/onboarding"
          element={<UserFormOdotobri />}
        />
        <Route
          path="/bancassurance/odotobri/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ODOTOBRI RURAL BANCASSURANCE" ? (
              <OdotobriEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
          // element={<WelcomeGcbEnquiry />}
        />
        <Route
          path="/bancassurance/odotobri/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ODOTOBRI RURAL BANCASSURANCE" ? (
              <OdotobriPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/yaaasantewaa"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "YAA ASANTEWAA RURAL BANCASSURANCE" ? (
              <YaaHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/yaaasantewaa/onboarding"
          element={<UserFormYaa />}
        />
        <Route
          path="/bancassurance/yaaasantewaa/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "YAA ASANTEWAA RURAL BANCASSURANCE" ? (
              <YaaEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
          // element={<WelcomeGcbEnquiry />}
        />
        <Route
          path="/bancassurance/yaaasantewaa/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "YAA ASANTEWAA RURAL BANCASSURANCE" ? (
              <YaaPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/atwimamponua"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ATWIMA MPONUA RURAL BANCASSURANCE" ? (
              <AtwimanMponuaHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/atwimamponua/onboarding"
          element={<UserFormAtw />}
        />
        <Route
          path="/bancassurance/atwimamponua/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ATWIMA MPONUA RURAL BANCASSURANCE" ? (
              <AtwimaMponuaEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
          // element={<WelcomeGcbEnquiry />}
        />
        <Route
          path="/bancassurance/atwimamponua/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "ATWIMA MPONUA RURAL BANCASSURANCE" ? (
              <AtwimaMponuaPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/southakim"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "SOUTH AKIM RURAL BANCASSURANCE" ? (
              <SouthAkimHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/southakim/onboarding"
          element={<UserFormSouthakim navigate={navigate} />}
        />
        <Route
          path="/bancassurance/southakim/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "SOUTH AKIM RURAL BANCASSURANCE" ? (
              <SouthakimEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
          // element={<WelcomeGcbEnquiry />}
        />
        <Route
          path="/bancassurance/southakim/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "SOUTH AKIM RURAL BANCASSURANCE" ? (
              <SouthakimPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/fiaseman"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "FIASEMAN RURAL BANCASSURANCE" ? (
              <FiasemanHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/fiaseman/onboarding"
          element={<UserFormFiaseman />}
        />
        <Route
          path="/bancassurance/fiaseman/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "FIASEMAN RURAL BANCASSURANCE" ? (
              <FiasemanEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
          // element={<WelcomeGcbEnquiry />}
        />
        <Route
          path="/bancassurance/fiaseman/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "FIASEMAN RURAL BANCASSURANCE" ? (
              <FiasemanPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />

        <Route path="/retail/gpcc/onboarding" element={<GPCCUserForm />} />
        <Route
          path="/retail/starlife/onboarding"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] === "" ? (
              <RetailOnboarding />
            ) : (
              <Navigate replace to={"/retail"} />
            )
          }
        />
        <Route
          path="/bancassurance/ahantaman"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "AHANTAMAN RURAL BANCASSURANCE" ? (
              <AhantamanHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/ahantaman/onboarding"
          element={<UserFormAhantaman />}
        />
        <Route
          path="/bancassurance/ahantaman/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "AHANTAMAN RURAL BANCASSURANCE" ? (
              <AhantamanEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
          // element={<WelcomeGcbEnquiry />}
        />
        <Route
          path="/bancassurance/ahantaman/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "AHANTAMAN RURAL BANCASSURANCE" ? (
              <AhantamanPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/garural"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "GA RURAL BANCASSURANCE" ? (
              <GaHome />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route
          path="/bancassurance/garural/onboarding"
          element={<UserFormGarural />}
        />
        <Route
          path="/bancassurance/garural/enquiry"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "GA RURAL BANCASSURANCE" ? (
              <GaEnquiry agn_code={authCtx.agnCode} />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
          // element={<WelcomeGcbEnquiry />}
        />
        <Route
          path="/bancassurance/garural/pending"
          element={
            authCtx.isDSOLoggedIn &&
            getDecryptedData("currDSO")["partner"] ===
              "GA RURAL BANCASSURANCE" ? (
              <GaPendingBusiness />
            ) : (
              <Navigate replace to={"/bancassurance"} />
            )
          }
        />
        <Route path="*" element={<NoMatchPage />} />
        {/* <Route path="/GCB">
          <WelcomeGcb />
        </Route> */}
      </Routes>
    </div>
  );
}

export default App;
