const BankBranchesData = [
  {
    bbrCode: 10415,
    bbrBnkCode: 1032,
    bbrBranchName: "ABURA",
    bbrShtDesc: "086017",
  },
  {
    bbrCode: 10416,
    bbrBnkCode: 1032,
    bbrBranchName: "HEADOFFICE",
    bbrShtDesc: "086010",
  },
  {
    bbrCode: 10417,
    bbrBnkCode: 1032,
    bbrBranchName: "AGONA",
    bbrShtDesc: "086011",
  },
  {
    bbrCode: 10418,
    bbrBnkCode: 1032,
    bbrBranchName: "KWESIMINTSIM",
    bbrShtDesc: "086012",
  },
  {
    bbrCode: 10419,
    bbrBnkCode: 1032,
    bbrBranchName: "AGONA MKT",
    bbrShtDesc: "086013",
  },
  {
    bbrCode: 10420,
    bbrBnkCode: 1032,
    bbrBranchName: "KOJOKROM",
    bbrShtDesc: "086014",
  },
  {
    bbrCode: 10421,
    bbrBnkCode: 1032,
    bbrBranchName: "DABOASE",
    bbrShtDesc: "086015",
  },
  {
    bbrCode: 10422,
    bbrBnkCode: 1032,
    bbrBranchName: "SEKONDI",
    bbrShtDesc: "086016",
  },
  {
    bbrCode: 10423,
    bbrBnkCode: 1032,
    bbrBranchName: "APOWA",
    bbrShtDesc: "086018",
  },
  {
    bbrCode: 10424,
    bbrBnkCode: 1032,
    bbrBranchName: "TANOKROM",
    bbrShtDesc: "086019",
  },
  {
    bbrCode: 10425,
    bbrBnkCode: 1032,
    bbrBranchName: "AXIM",
    bbrShtDesc: "086020",
  },
  {
    bbrCode: 10426,
    bbrBnkCode: 1032,
    bbrBranchName: "NSUAEM",
    bbrShtDesc: "086021",
  },
  {
    bbrCode: 10427,
    bbrBnkCode: 1032,
    bbrBranchName: "ANAJI",
    bbrShtDesc: "086022",
  },
  {
    bbrCode: 10428,
    bbrBnkCode: 1032,
    bbrBranchName: "TARKWA",
    bbrShtDesc: "086023",
  },
  {
    bbrCode: 10429,
    bbrBnkCode: 1032,
    bbrBranchName: "HOLYCHILD",
    bbrShtDesc: "086024",
  },
  {
    bbrCode: 10430,
    bbrBnkCode: 1032,
    bbrBranchName: "EFFIA",
    bbrShtDesc: "086025",
  },
  {
    bbrCode: 10431,
    bbrBnkCode: 1032,
    bbrBranchName: "ACCRA STATION",
    bbrShtDesc: "086026",
  },
  {
    bbrCode: 10432,
    bbrBnkCode: 1032,
    bbrBranchName: "ADUM BANSO",
    bbrShtDesc: "086027",
  },
  {
    bbrCode: 10433,
    bbrBnkCode: 1032,
    bbrBranchName: "TWIFO PRASO",
    bbrShtDesc: "086028",
  },
  {
    bbrCode: 10434,
    bbrBnkCode: 1032,
    bbrBranchName: "AIYINASE",
    bbrShtDesc: "086029",
  },
];

export default BankBranchesData;
